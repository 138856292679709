import React from "react";

import classNames from "classnames";
import { ErrorMessage, Field } from "formik";

import {
  getRequiredMark,
  getValidationClass,
  getWrapperClass,
} from "../../../../helpers/form";

import styles from "./Textfield.module.scss";


const Textfield = ({ field, formik }) => {
  const { errors, touched, handleChange } = formik;

  const {
    required,
    title,
    webform_key,
    webform_id,
    attributes,
    wrapperAttributes,
  } = field;

  let isRequired = required === "true";
  let requiredMark = getRequiredMark(isRequired);

  const ref = React.useRef(null);
  const [hasValue, setHasValue] = React.useState(false);

  const handleOnChange = (event) => {
    handleChange(event);

    const value = ref.current.value.trim();
    setHasValue(value !== "");
  };

  let wrapperAttributesClass = "";
  if (wrapperAttributes?.class?.length > 0) {
    wrapperAttributesClass = wrapperAttributes.class.join(" ");
  }

  const attributesClass = attributes?.class ?? "";
  const validationClass = getValidationClass(
    errors[webform_key],
    touched[webform_key]
  );

  const hasErrors = errors[webform_key] && touched[webform_key] ? true : false;

  const fieldClass = `${attributesClass} ${validationClass}`.trim();

  let wrapperClass = getWrapperClass(wrapperAttributesClass);
  const containerClass = `form-group ${webform_key}-container`;

  const cssHasValue = hasValue ? styles["input--has-value"] : undefined;
  const inputClassNames = classNames(styles.input, cssHasValue, fieldClass);

  return (
    <div className={`${containerClass} ${wrapperClass}`}>
      <div className={classNames(styles.wrapper, "field-error-group")}>
        <label htmlFor={webform_id} className={styles.label}>
          {title}
          {requiredMark}
        </label>
        <Field
          id={webform_id}
          name={webform_key}
          required={isRequired}
          className={inputClassNames}
          onChange={handleOnChange}
          innerRef={ref}
        />
      </div>
      {hasErrors && (
        <p className={styles.error}>
          <ErrorMessage name={webform_key} />
        </p>
      )}
    </div>
  );
};

export default Textfield;
