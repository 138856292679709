import { lazy, Suspense, useContext, useEffect, useRef } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

import ViewLoader from "../components/Loader/ViewLoader/ViewLoader";
import { BreadCrumbContext } from "../context/BreadCrumbContext";
import { AppContext } from "../context/ContextWrapper";
import { getNodeApiUrl, submitRequest } from "../helpers/api";
import { sendViewEvent } from "../helpers/gtm";
import { isCorrectLanguage } from "../helpers/locales";
import { getTemplateFromNode } from "../helpers/node";
import useNodeData from "../hooks/use-node-data";
import locales from "../locales";
import NotFound from "./not-found";


/**
 *  This component decides which view needs to be rendered
 *  for the current route.
 */

export default function ViewSwitch() {
  const { setVideo } = useContext(AppContext);
  const { setBreadcrumbs } = useContext(BreadCrumbContext);

  const data = useLoaderData();
  const { node = null } = data;

  const { setNodeData } = useNodeData();

  const setNodeDataRef = useRef(null);
  setNodeDataRef.current = setNodeData;

  useEffect(() => {
    const videoOptions = [2, 3, 5];
    const randomVideoId =
      videoOptions[Math.trunc(Math.random() * videoOptions.length)];
    setVideo(randomVideoId);

    if (!node) {
      setNodeDataRef.current(null);
      return;
    }

    node
      .then((nodeData) => {
        setNodeDataRef.current(nodeData);
        setBreadcrumbs(nodeData.breadcrumbs ?? null);

        sendViewEvent(nodeData);
      })
      .catch((err) => {
        setNodeDataRef.current(null);
      });
  }, [node, setBreadcrumbs, setVideo]);

  if (!node) {
    return <NotFound />;
  }

  return (
    <Suspense fallback={<ViewLoader />}>
      <Await resolve={node} errorElement={<NotFound />}>
        {(node) => {
          if (node.error) {
            return <NotFound />;
          }

          const template = getTemplateFromNode(node);
          const ViewComponent = lazy(() => import(`./${template}`));

          return (
            <Suspense fallback={<ViewLoader />}>
              <ViewComponent node={node} />
            </Suspense>
          );
        }}
      </Await>
    </Suspense>
  );
}

export async function loader({ params }) {
  const { locale, "*": splat } = params;

  const nullNode = { node: null };

  if (!isCorrectLanguage(locales, locale)) {
    return nullNode;
  }

  const slug = splat ? `/${splat}` : "/home";

  const url = getNodeApiUrl(locale, slug);

  if (!url) {
    return nullNode;
  }

  const nodePromise = submitRequest(url);

  return defer({
    node: nodePromise.then((response) => response.json()),
  });
}
