import { useContext } from "react";

import { AppContext } from "../../context/ContextWrapper";

import styles from "./DecorativeMovil.module.scss";

const DecorativeMovil = () => {
  const { video } = useContext(AppContext);

  const videoName = `kutxa_anim_${video}_ok`;

  return (
    <div className={styles.decorativeVideo}>
      <video
        key={video}
        className={styles.video}
        muted
        autoPlay
        loop
        playsInline
      >
        <source src={`/videos/${videoName}.webm`} type="video/webm" />
        <source src={`/videos/${videoName}.mp4`} type="video/mp4" />
      </video>
    </div>
  );
};

export default DecorativeMovil;
