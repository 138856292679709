import { useContext } from "react";

import { AppContext } from "../../context/ContextWrapper";

import styles from "./DecorativeVideo.module.scss";

const DecorativeVideo = () => {
  const { video } = useContext(AppContext);

  const videoName = `kutxa_anim_${video}_ok`;

  return (
    <div className={styles.wrapper_video}>
      <video key={video} className={styles.video} muted autoPlay loop>
        <source src={`/videos/${videoName}.webm`} type="video/webm" />
        <source src={`/videos/${videoName}.mp4`} type="video/mp4" />
      </video>
    </div>
  );
};

export default DecorativeVideo;
