import PropTypes from "prop-types";

import LinkPrefetch from "./LinkPrefetch";

const Link = ({ children, to, target, title, ...other }) => {
  const href = to;

  const regularLink = (
    <a
      href={href}
      target="_blank"
      title={title ? title : undefined}
      rel="noopener noreferrer"
      {...other}
    >
      {children}
    </a>
  );

  if (target === "_blank") {
    // Referer header: privacy and security concerns:
    // https://developer.mozilla.org/en-US/docs/Web/Security/Referer_header:_privacy_and_security_concerns

    return regularLink;
  }

  // Starts with exactly one slash.
  const internal = /^\/(?!\/)/.test(href);
  if (internal) {
    return (
      <LinkPrefetch to={href} {...other} title={title}>
        {children}
      </LinkPrefetch>
    );
  }

  return regularLink;
};

export default Link;

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};
